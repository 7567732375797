import { SafetyCodeContext } from '@canalplus/types-acm';
import fr_fr from './fr-fr';
import { OneDiscoveryTranslations } from './types';

// File to override sentences for african country

const LOCALE_FR_AFR: OneDiscoveryTranslations = {
  ...fr_fr,
  SafetyDisclaimer: {
    ...fr_fr.SafetyDisclaimer,
    [SafetyCodeContext.Parental]: {
      ...fr_fr.SafetyDisclaimer[SafetyCodeContext.Parental],
      warningDescription:
        'Ce service est verrouillé car il propose un programme réservé à un public averti (programmes interdits aux moins de 16 ans, érotiques ou de grandes violences).',
      conditions: '',
    },
  },
};

export default LOCALE_FR_AFR;
